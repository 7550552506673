import { FC, ReactElement } from "react";
import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import InputMask from "react-input-mask";

import { MasterCardLogo, VisaLogo } from "../../../assets/png";
import theme from "../../../Theme";

// eslint-disable-next-line import/no-unresolved
import { ErrorAnimation } from "../../../animation";

// Defining Input Field Props
type InputFieldProps = {
  label: string;
  placeholder: string;
  name: string;
  dateField?: boolean | false;
  hasError?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean | false;
  [key: string]: any;
};

// Deifining Mask interface
interface Masks {
  phoneNumber: string;
  cardNumber: string;
  cvv: string;
  bankNumber: string;
  transitNumber: string;
  accountNumber: string;
  default: string;
  [key: string]: string;
}

// Defining Masks
const masks: Masks = {
  phoneNumber: "999-999-9999",
  cardNumber: "9999 9999 9999 9999",
  bankNumber: "999",
  transitNumber: "99999",
  accountNumber: "999999999999",
  postalCode: "*** ***",
  cvv: "999",
  default: "99",
};

// Defining Credit Card regex
const VISA_REGEX: RegExp = /^4/;
const MASTER_CARD_REGEX: RegExp = /^5/;

const MaskedInput: FC<InputFieldProps> = (
  props: InputFieldProps
): ReactElement => {
  const mask: string = masks[props.name] || masks.default;

  // Selecting Card Logo
  let cardLogo = null;
  if (props.name === "cardNumber") {
    const val = props.value.replace(/ /g, "").substring(0, 5);
    // const val = props.value.replaceAll(" ","").substring(0, 5);
    if (VISA_REGEX.test(val)) {
      cardLogo = <img src={VisaLogo} alt="Visa" width={50} height={45} />;
    } else if (MASTER_CARD_REGEX.test(val)) {
      cardLogo = (
        <img src={MasterCardLogo} alt="master" width={50} height={30} />
      );
    }
  }
  return (
    <>
      <Typography
        sx={{
          mb: 1,
          visibility: `${props.label === "" ? "hidden" : "block"}`,
          whiteSpace: "nowrap",
        }}
      >
        {props.label}
        <Typography sx={{ color: "error.main" }} component="span">
          {props.isRequired && " *"}
        </Typography>
      </Typography>
      <InputMask
        mask={mask}
        maskChar={props.maskChar || "_"}
        maskPlaceholder={props.maskPlaceholder || null}
        alwaysShowMask={false}
        value={props.value}
        onClick={(event: any) => {
          props.name === "phoneNumber" &&
            event.target.setSelectionRange(
              (props.value.match(/\d/g) || []).length > 9
                ? (props.value.match(/\d/g) || []).length + 2
                : (props.value.match(/\d/g) || []).length >= 3
                ? (props.value.match(/\d/g) || []).length + 1
                : (props.value.match(/\d/g) || []).length,
              (props.value.match(/\d/g) || []).length >= 9
                ? (props.value.match(/\d/g) || []).length + 2
                : (props.value.match(/\d/g) || []).length >= 3
                ? (props.value.match(/\d/g) || []).length + 1
                : (props.value.match(/\d/g) || []).length
            );
        }}
        onChange={(event: any) => {
          props.onChange(event);
          event.target.setSelectionRange(
            (props.value.match(/\d/g) || []).length > 9
              ? (props.value.match(/\d/g) || []).length + 2
              : (props.value.match(/\d/g) || []).length >= 3
              ? (props.value.match(/\d/g) || []).length + 1
              : (props.value.match(/\d/g) || []).length,
            (props.value.match(/\d/g) || []).length >= 9
              ? (props.value.match(/\d/g) || []).length + 2
              : (props.value.match(/\d/g) || []).length >= 3
              ? (props.value.match(/\d/g) || []).length + 1
              : (props.value.match(/\d/g) || []).length
          );
        }}
        disabled={
          (sessionStorage?.getItem("AccessToken") &&
            (props.name === "bankNumber" ||
              props.name === "transitNumber" ||
              props.name === "accountNumber")) ||
          props.isDisabled
            ? true
            : false
        }
      >
        {props.name === "cardNumber" ? (
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              notched: false,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>{cardLogo}</IconButton>
                </InputAdornment>
              ),
              sx: {
                backgroundColor: "ghostwhite",
              },
            }}
            InputLabelProps={{ shrink: false }}
            // inputProps={{ style: { textTransform: "uppercase" } }}
            sx={{
              padding: "2px 2px",
              color: theme.palette.secondary.main,
              animation: `${props.hasError ? ErrorAnimation : ""} 0.5s`,
              "& .MuiInputBase-root": {
                backgroundColor: props.hasError ? "#fff" : "ghostwhite",
              },
              "& .MuiFormHelperText-root": {
                color: "error.main",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: `${
                    props.hasError ? "error.main" : "ghostwhite"
                  }`,
                },
              },
            }}
            placeholder={props.placeholder}
            name={props.name}
            helperText={props.helperText}
            type={props.type}
          />
        ) : (
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              notched: false,
              sx: {
                backgroundColor: "ghostwhite",
              },
            }}
            InputLabelProps={{ shrink: false }}
            // inputProps={{ style: { textTransform: "none" } }}
            sx={{
              padding: "2px 2px",
              color: theme.palette.secondary.main,
              animation: `${props.hasError ? ErrorAnimation : ""} 0.5s`,
              "& .MuiInputBase-root": {
                backgroundColor: props.hasError ? "#fff" : "ghostwhite",
              },
              "& .MuiFormHelperText-root": {
                color: "error.main",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: `${
                    props.hasError ? "error.main" : "ghostwhite"
                  }`,
                },
              },
            }}
            placeholder={props.placeholder}
            name={props.name}
            helperText={props.helperText}
            type={props.type}
          />
        )}
      </InputMask>
    </>
  );
};

export default MaskedInput;
