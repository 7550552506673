import { ReactElement, useMemo } from "react";
import { Box, Grid, Icon, Typography, Link } from "@mui/material";
import {
  CreditLogo,
  FacebookIcon,
  LinkedInIcon,
  MailIcon,
  TwitterIcon,
} from "../../assets/svg";
import { FOOTER_TABS, FOOTER_TABS_BNPL } from "../../constants";
import { createTheme } from "@mui/material/styles";
import { getReferalCode } from "../../utils";
import StyledContainer from "../Custom/Container";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  // Other theme configurations
});

export default function Footer(): ReactElement {
  const referralCode = getReferalCode();
  const footerCols = useMemo(() => {
    return referralCode ? FOOTER_TABS_BNPL : FOOTER_TABS;
  }, [referralCode]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "ghostwhite",
        bottom: 0,
        color: "secondary.main",
      }}
    >
      <StyledContainer maxWidth="lg">
        <Icon
          sx={{
            width: "175px",
            height: "100px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={CreditLogo}
            alt="Logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Icon>

        <Grid container direction="column" alignItems="center">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "4%",
              mb: "30px",
              width: "100%",
              [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                mb: "75px",
              },
            }}
          >
            {footerCols.map((tab) => (
              <Link
                sx={{
                  textDecoration: "none",
                  color: "inherit",
                  "&:hover": {
                    color: "#3075FF",
                  },
                }}
                href={tab.href}
                target="_blank"
                rel="noopener"
              >
                <Typography key={tab.name}>{tab.name}</Typography>
              </Link>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
            }}
          >
            {!referralCode && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5%",
                  }}
                >
                  <Icon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "blue",
                    }}
                  >
                    <img
                      src={MailIcon}
                      alt="Mail"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Icon>
                  <Link
                    target="_blank"
                    rel="noopener"
                    sx={{
                      textDecoration: "none",
                      color: "#3075FF",
                    }}
                  >
                    Support@ontab.com
                  </Link>
                </Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "0.9rem",
                  }}
                >
                  10 Dundas St. East, Suit 600 Toronto ON M5B2G9
                </Typography>
              </>
            )}
            <Box sx={{ display: "inline" }}>
              <Icon
                sx={{
                  width: "25px",
                  height: "25px",
                  color: "blue",
                  mx: "10px",
                }}
              >
                <Link
                  href="https://www.facebook.com/ontabinc"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    src={FacebookIcon}
                    alt="Facebook"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Link>
              </Icon>
              <Icon
                sx={{
                  width: "25px",
                  height: "25px",
                  color: "blue",
                  mx: "10px",
                }}
              >
                <Link
                  href="https://www.linkedin.com/company/ontab/"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    src={LinkedInIcon}
                    alt="LinkedIn"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Link>
              </Icon>
              <Icon
                sx={{
                  width: "25px",
                  height: "25px",
                  color: "blue",
                  mx: "10px",
                }}
              >
                <Link
                  href="https://twitter.com/ontabinc"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    src={TwitterIcon}
                    alt="Twitter"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Link>
              </Icon>
            </Box>
            <Typography>Copyright © ONTAB Inc.</Typography>
          </Box>
        </Grid>
      </StyledContainer>
    </Box>
  );
}
